
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of orders</h5>
      <div class="card">
        <div class="card-header">
          <label class="mb-2">Filter by status</label>
          <div>
            <div v-for="(status, sIndex) in statusOptions" :key="sIndex"
               class="form-check form-check-inline">
              <input v-model="defaultStatus" :id="`inlineRLRadio_${status.value}`" class="form-check-input" 
              type="radio" name="inlineRadioOptions" :value="status.value">
              <label class="form-check-label" :for="`inlineRLRadio_${status.value}`">{{status.label}}</label>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-2 gy-2">
            <div class="col-sm-12 col-md-6 d-flex">
              <div id="tickets-table_length" class="me-2 dataTables_length">
                <label class="d-inline-flex mb-0 align-items-center">
                  <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
                </label>
              </div>
              <div class="dropdown no-arrow me-2">
                <a class="btn btn-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri ri-filter-3-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <button @click="selectAllRows()" class="dropdown-item" type="button">
                       {{isSelectAll? 'Invert Selection' : 'Select all' }}
                    </button>
                  </li>
                  <li @click="updateStatus()">
                    <button class="dropdown-item" type="button">
                     Update Status
                    </button>
                  </li>
                  <li @click="marketingMail()">
                    <button class="dropdown-item" type="button">
                      Send e-Mail
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="form-inline text-sm-end">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex mb-0 align-items-center">
                    <b-form-input v-model="searchQuery" type="search" placeholder="Search number..." class="form-control"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table striped hover ref="selectableTable" :items="paginatedItems"
                :fields="columns" responsive="sm">
                <template v-slot:cell(check)="data">
                  <div style="width:3rem;" class="form-check text-center me-auto">
                    <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                    <label class="form-check-label" :for="`tableSRadio${data.item.id}`">{{data.item.id}}</label>
                  </div>
                </template>
                <template v-slot:cell(number)="data">
                  <div @click="navigateTo(data.item.id)" class="cursor-pointer">
                    #{{ data.item.number }}
                  </div>
                </template>
                <template v-slot:cell(buyer)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer" v-if="data.item.buyer">
                    <span class="text-dark font-sm">{{ data.item.buyer.first_name }} {{ data.item.buyer.last_name }}</span>
                  </div>
                </template>
                <template v-slot:cell(product)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer" v-if="data.item.product">
                    <span class="text-dark font-sm">{{ data.item.product.name }}</span>
                  </div>
                </template>
                <template v-slot:cell(price)="data">
                  <div @click="navigateTo(data.item.id)" style="width:5rem;" class="cursor-pointer">
                    ${{ $filters.money_format(data.item.amount) }}
                  </div>
                </template>
                <template v-slot:cell(date)="data">
                  <div @click="navigateTo(data.item.id)" style="width:6rem;" class="cursor-pointer">
                    {{$filters.date_time(data.item.created_at, 2) }}
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <ul class="list-inline table-action m-0">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" @click="confirmDelete(data.item.id)" class="text-danger">
                        <i class="ri-delete-bin-2-line"></i></a>
                    </li>
                  </ul>
                </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded">
                  <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" 
                  :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal size="md" title="Update Status"
      body-bg-variant="white" body-class="p-3"
        hide-footer v-model="modalUpdateStatus">
      <div>
        <order-status-form v-if="modalUpdateStatus" :selected="selected" @feedback="initializeAll()" />
      </div>
    </b-modal>
    <b-modal size="lg" title="Marketing Mail"
      body-bg-variant="white" body-class="p-3"
        hide-footer v-model="modalMarketingMail">
      <div>
        <marketing-mail-form v-if="modalMarketingMail"
        :selectedUsers="selectedUsers"
        @feedback="initializeAll()" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrderStatusForm from "@/components/forms/OrderStatusForm.vue";
import MarketingMailForm from "@/components/forms/MarketingMailForm.vue";

export default {
  name: "order-list",
  components:{
    OrderStatusForm,
    MarketingMailForm
  },
  props:{
    orderList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false,
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      defaultStatus:"all",
      statusOptions:[
        { value: "all", label: "All", },{
          value: "draft", label: "Draft",
        },{ value: "active", label: "Active" },{
          value: "delivered", label: "Delivered",
        },{ value: "completed", label: "Completed",},{
          value: "refunded", label: "Refunded",
        }
      ],
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      isSelectAll: false,
      columns: [
        {
            key: "check",
            label: "ID"
        },
        {
            key: "number",
            label: "Number",
            sortable: true
        },
        {
            key: "buyer",
            label: "Buyer",
            sortable: true
        },
        {
            key: "product",
            label: "Product",
            sortable: true
        },
        {
            key: "price",
            label: "Price",
            sortable: true
        },
        {
            key: "date",
            label: "Date",
            sortable: true
        },
        "action"
      ],
      selected: [],
      modalUpdateStatus: false,
      modalMarketingMail: false,
      selectedUsers: []
    }
  },
  watch: {
    '$route.query': function (query){
      this.refreshQueryStatus()
    },
  },
  computed: {
    searchedItems(){
      return this.defaultStatus == "all"
            ? this.orderList.filter(item => item.number ? item.number.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
            : this.orderList.filter((item) => (item.status == this.defaultStatus))
              .filter(item => item.number ? item.number.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
    paginatedItems(){
      return this.searchedItems.slice(
        this.paginateStart,
        this.paginateEnd
      );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/orders/${id}`});
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.orderList.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    refreshQueryStatus(){
      if(this.$route.query.status){
        this.defaultStatus = this.$route.query.status
      }
    },
    updateStatus(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.modalUpdateStatus = true
    },
    async marketingMail(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.selectedUsers = this.orderList.filter((ord) => this.selected.includes(ord.id)).map((ord) => {
        return {id: ord.buyer.id, first_name: ord.buyer.first_name, email: ord.buyer.email,}
      })
      this.modalMarketingMail = true
    },
    confirmDelete(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete an order permanently",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
                this.$store.commit("orderList/DELETE_ORDER",itemId)
            }
          })
        }
      });
    },
    initializeAll(){
      this.modalUpdateStatus = false,
      this.modalMarketingMail = false
      this.selectedUsers = []
    }
  },
   mounted() {
    this.refreshQueryStatus()
  },
}

</script>

