
<template>
<div>
  <div class="row">
    <div class="col-12">
      <order-list-table :orderList="orders" />
    </div>
  </div>
</div>
</template>

<script>

import OrderListTable from "@/components/order/OrderListTable.vue"

export default {
  components: {
    OrderListTable,
  },
  computed:{
    orders() {
      return this.$store.state.orderList.orders
    },
  }
}
</script>
